import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import { RichTextView, device, ContentMain } from '../components/commonStyled';
import { SuccessStoryItemModel, AvailableSections } from '../models';
import { FONT_GOTHAM_MEDIUM } from '../styles/fonts';
import Sections from '../components/Sections';
import SectionWithSliderSticked from '../components/common/SectionWithSliderSticked/SectionWithSliderSticked';
import SuccessStoryItem from '../components/SuccessStories/SuccessStoryItem';
import { useSuccessStories } from '../queries/useSuccessStories';
import { ROUTES } from '../constants';

interface SuccessStoryProps {
    data: {
        wpSuccessStory: any;
    };
    location: any;
}

const Container = styled(ContentMain)`
    display: block;
    margin-bottom: 46px;
`;

const ImageWrap = styled.div`
    width: 100%;
    height: auto;
    border-radius: 33px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
    overflow: hidden;
`;

const RichTextViewStyled = styled(RichTextView)`
    line-height: 30px;
`;

const PhotoWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    width: 344px;
    margin-right: 36px;
    padding-bottom: 12px;

    @media ${device.mobileMedium} {
        float: none;
        margin: 0 auto;
    }
`;
const Name = styled.div`
    margin: 20px 0 8px 0;
    color: #000000;
    font-size: 24px;
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const Description = styled.div`
    color: #000000;
    font-size: 14px;
    line-height: 24px;
`;

const SuccessStory: React.FC<SuccessStoryProps> = ({ data, location }) => {
    const { title, seo } = data?.wpSuccessStory;
    const item = data?.wpSuccessStory
        ?.success_stories_acf as SuccessStoryItemModel;

    const otherStories = useSuccessStories();

    const { success_full_photo, jobtitle, name, contacts, feedback } = item;

    return (
        <PageLayout
            breadcrumbs={[
                {
                    title: 'Истории успеха',
                    url: `/${ROUTES.istoriiUspeha}`,
                },
                {
                    title,
                    url: location?.pathname,
                },
            ]}
            title="Истории успеха"
            seoMeta={seo}
        >
            <Container>
                {success_full_photo?.localFile && (
                    <PhotoWrap>
                        <ImageWrap>
                            <CompatibleImg
                                image={success_full_photo.localFile}
                            />
                        </ImageWrap>
                        <Name>{name}</Name>
                        <Description>{jobtitle}</Description>
                        <Description>{contacts}</Description>
                    </PhotoWrap>
                )}
                <RichTextViewStyled
                    fontSize={24}
                    dangerouslySetInnerHTML={{ __html: feedback }}
                />
            </Container>
            <SectionWithSliderSticked<SuccessStoryItemModel>
                items={otherStories.filter((x) => x.name !== item.name)}
                title="Больше историй успеха"
                sliderElement={SuccessStoryItem}
                sliderId="success_stories"
            />
            <Sections
                enabledSections={[
                    AvailableSections.courses,
                    AvailableSections.calendarEvents,
                    AvailableSections.actionBlockMiddle,
                    AvailableSections.form,
                ]}
                options={{
                    actionBlockMiddle: {
                        whiteStyle: true,
                        noButton: true,
                    },
                }}
            />
        </PageLayout>
    );
};

export default SuccessStory;

export const pageQuery = graphql`
    query SuccessStory($id: String!) {
        wpSuccessStory(id: { eq: $id }) {
            title
            seo {
                title
            }
            success_stories_acf {
                name
                photo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 600)
                        }
                    }
                    altText
                }
                jobtitle
                shortFeedback
                contacts
                feedback
                success_full_photo: successFullPhoto {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 600)
                        }
                    }
                    altText
                }
            }
        }
    }
`;
